const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

const reportMixin = {
    methods: {
        $_reportMixin_setWidthColumn(ws, header, data) {
            let objectMaxLength = header.map((h) => `${h}`.length);

            // console.log(objectMaxLength);

            for (let i = 0; i < data.length; i++) {
                let value = Object.values(data[i]);

                for (let j = 0; j < value.length; j++) {
                    const valueString = `${value[j]}`;

                    objectMaxLength[j] =
                        objectMaxLength[j] >= valueString.length
                            ? objectMaxLength[j]
                            : valueString.length;
                }
            }

            // console.log(objectMaxLength);

            this.$set(
                ws,
                "!cols",
                objectMaxLength.map((obj) => ({ width: obj }))
            );

            return ws;
        },

        $_reportMixin_mergeCellBy(ws, colKey, { orderCol = "A", headerRow = 1, mergedCol = {} } = {}) {
            let colKeyRef, colKeyIndex,
                mergedColRefs = {},
                mergedColIndice = {};

            for (let index = 0; index < alphabets.length; index++) {
                const alphabet = alphabets[index];

                const colHeader = ws[`${alphabet}${headerRow}`];

                if (colHeader) {
                    if (colHeader && colHeader.v === colKey) {
                        colKeyRef = alphabet;
                        colKeyIndex = index;
                    } else if (mergedCol[colHeader.v]) {
                        mergedColRefs[colHeader.v] = alphabet;
                        mergedColIndice[colHeader.v] = index;
                    }

                }
            }

            if (colKeyRef) {
                const keyIndices = {};
                const keyOrders = {};
                let order = 1;
                const sheetRange = ws["!ref"];
                const lastCell = sheetRange.split(":")[1];
                const lastRow = lastCell.match(/\d+/)[0];

                for (let index = 2; index <= lastRow; index++) {
                    const cell = ws[`${colKeyRef}${index}`];

                    const keyValue = cell.v;

                    if (!keyIndices[keyValue]) {
                        keyIndices[keyValue] = [];
                        keyOrders[order] = keyValue;
                        order += 1;
                    }

                    keyIndices[keyValue].push(index);
                }

                const mergeRanges = [];

                for (const keyOrder in keyOrders) {
                    if (Object.hasOwnProperty.call(keyOrders, keyOrder)) {
                        const keyValue = keyOrders[keyOrder];
                        const keyIndice = keyIndices[keyValue].sort((a, b) => a - b);
                        const startRow = keyIndice[0];
                        const endRow = keyIndice[keyIndice.length - 1];

                        // console.log(
                        //     keyOrder,
                        //     keyValue,
                        //     keyIndice,
                        //     startRow,
                        //     endRow,
                        //     colKeyIndex,
                        //     ws[`${orderCol}${startRow}`],
                        //     mergedCol,
                        //     mergedColRefs,
                        //     mergedColIndice
                        // );

                        this.$set(ws[`${colKeyRef}${startRow}`], "v", keyValue);

                        mergeRanges.push({
                            s: { r: startRow - 1, c: colKeyIndex },
                            e: { r: endRow - 1, c: colKeyIndex },
                        });

                        this.$set(ws[`${orderCol}${startRow}`], "v", keyOrder);

                        mergeRanges.push({
                            s: { r: startRow - 1, c: 0 },
                            e: { r: endRow - 1, c: 0 },
                        });

                        Object.entries(mergedCol).forEach(entry => {
                            const col = entry[0];
                            const fn = entry[1];
                            const mergedColRef = mergedColRefs[col];
                            const mergedColIndex = mergedColIndice[col];

                            const records = [];

                            for (let row = startRow; row <= endRow; row++) {
                                const cell = ws[`${mergedColRef}${row}`];

                                records.push(cell);
                            }

                            const keyValue = fn(records);

                            this.$set(ws[`${mergedColRef}${startRow}`], "v", keyValue);

                            mergeRanges.push({
                                s: { r: startRow - 1, c: mergedColIndex },
                                e: { r: endRow - 1, c: mergedColIndex },
                            });
                        })
                    }
                }

                if (mergeRanges.length) {
                    this.$set(ws, "!merges", mergeRanges);
                }
            }

            return ws;
        }
    }
};

export default reportMixin;
