<template>
  <div class="d-flex flex-row flex-wrap">
    <div class="col-3 px-2">
      <label class="font-weight-bold ft-s-14" for="fiscalYearFrom"
        >เริ่มปีการศึกษา</label
      >

      <!-- <b-form-input
              id="fiscalYearFrom"
              v-model="fiscalYearFrom"
            ></b-form-input> -->
      <v-select
        id="fiscalYearFrom"
        v-model="form.fiscalYearFrom"
        :options="fiscalYears"
        :clearable="isNotDefaultValue(form.fiscalYearFrom)"
        @input="onInput($event, 'fiscalYearFrom')"
      ></v-select>
    </div>
    <b-col cols="3" class="px-2">
      <label class="font-weight-bold ft-s-14" for="termFrom">ภาคเรียน</label>

      <v-select
        id="termFrom"
        v-model="form.termFrom"
        :options="terms"
        :clearable="isNotDefaultValue(form.termFrom)"
        @input="onInput($event, 'termFrom')"
      ></v-select>
    </b-col>

    <div class="col-3 px-2">
      <label class="font-weight-bold ft-s-14" for="fiscalYearTo"
        >ถึงปีการศึกษา</label
      >

      <!-- <b-form-input v-model="fiscalYearTo"></b-form-input> -->
      <v-select
        id="fiscalYearTo"
        v-model="form.fiscalYearTo"
        :options="fiscalYears"
        :clearable="isNotDefaultValue(form.fiscalYearTo)"
        @input="onInput($event, 'fiscalYearTo')"
      ></v-select>
    </div>
    <b-col cols="3" class="px-2">
      <label class="font-weight-bold ft-s-14" for="termTo">ภาคเรียน</label>

      <v-select
        id="termTo"
        v-model="form.termTo"
        :options="terms"
        :clearable="isNotDefaultValue(form.termTo)"
        @input="onInput($event, 'termTo')"
      ></v-select>
    </b-col>
  </div>
</template>

<script>
import vSelect from "vue-select";

const currentYear = new Date().getFullYear() + 543;

export default {
  props: {
    vModel: Object,
    defaultValue: {
      type: String,
      default: "ทั้งหมด",
    },
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  components: {
    vSelect,
  },

  data() {
    return {
      terms: [this.defaultValue, "1", "2"],
    };
  },

  computed: {
    form: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },

    fiscalYears() {
      return [
        this.defaultValue,
        currentYear - 5,
        currentYear - 4,
        currentYear - 3,
        currentYear - 2,
        currentYear - 1,
        currentYear,
        currentYear + 1,
        currentYear + 2,
        currentYear + 3,
        currentYear + 4,
        currentYear + 5,
      ];
    },
  },

  methods: {
    isNotDefaultValue(value) {
      return value !== this.defaultValue;
    },

    onInput(value, dataName) {
      if (!value) {
        this.$set(this.form, dataName, this.defaultValue);
      }
    },
  },
};
</script>
